<template>
  <v-container id="admins-page" fluid tag="section">
    <v-row class="mx-0" align="center" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12">
        <base-material-card color="primary" class="px-5 py-3 w-100">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Administrator Registration
            </div>
            <div class="subtitle-1 font-weight-light">
              Register your new Administrators here
            </div>
          </template>
          <v-card-text class="w-100 px-3 py-5" ref="form">
            <v-row class="w-100 mx-0" align="center" justify="center">
              <v-col cols="12">
                <v-form ref="form" @submit.prevent="regFn">
                  <v-row align="center" justify="center" justify-sm="start">
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-text-field
                        ref="name"
                        v-model="userName"
                        label="Name"
                        outlined
                        :error-messages="errorUserName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-text-field
                        ref="emailAddress"
                        v-model="emailAddress"
                        label="Email"
                        outlined
                        :rules="[
                          () =>
                            !!checkEmailFn(emailAddress) ||
                            'This email is invalid ',
                        ]"
                        :error-messages="errorEmailAddress"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-text-field
                        type="password"
                        ref="Password"
                        v-model="password"
                        label="Password"
                        outlined
                        :error-messages="errorPassword"
                      ></v-text-field>
                      <!-- :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                      @click:append="show = !show"-->
                    </v-col>
                    <!-- <v-row align="center" justify="center">
                <span style="color: red;">{{mainerror}}</span>
                    </v-row>-->

                    <!-- :loading="isApiLoad" -->
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-select
                        type="select"
                        ref="key"
                        v-model="adType"
                        label="Admin Type"
                        item-text="name"
                        item-value="value"
                        outlined
                        :error-messages="errorAdType"
                        :items="adminTypes"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <!-- <v-row align="center" justify="center">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    type="password"
                    ref="key"
                    v-model="secKey"
                    label="Secret Key"
                    outlined
                    :error-messages="errorSecKey"
                  ></v-text-field>
                </v-col>
                  </v-row>-->

                  <span class="caption text-center error--text">{{
                    mainError
                  }}</span>

                  <v-row align="center" justify="center">
                    <v-col
                      cols="6"
                      class="
                        d-flex
                        flex-column
                        align-center
                        justify-space-between
                        py-0
                      "
                    >
                      <v-btn
                        type="submit"
                        class="px-12 mb-3 mx-auto"
                        color="secondary"
                        tile
                        depressed
                        bottom
                        >Submit</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Administrators</div>
            <div class="subtitle-1 font-weight-light">
              A list of all Administrators
            </div>
          </template>
          <v-card-text>
            <v-row
              class="mx-0"
              align="center"
              justify="center"
              justify-md="end"
            >
              <v-col cols="12" md="8" lg="5">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="px-0">
                <v-data-table
                  :headers="headers"
                  :search="search"
                  :items="items"
                  :loading="isLoading"
                >
                  <template v-slot:item.email="{ item }">
                    <v-tooltip bottom v-if="item.email">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                        </span>
                      </template>
                      <span>
                        Send an email to
                        <b>{{ item.name }}</b> via
                        <b>
                          <i>{{ item.email }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.adminType="{ item }">
                    <span class="text-left text-capitalize">{{
                      item.adminType == "superAdmin" ? "Super Admin" : "Admin"
                    }}</span>
                  </template>
                  <template v-slot:item.status>
                    <v-btn icon color="grey lighten-1">
                      <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </v-btn>
                  </template>
                  <template v-slot:item.approval="{ item }">
                    <DisableConfirmation
                      :item="item"
                      :approvalFn="approvalFn"
                    />
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <DeleteConfirmation
                      :key="item._id"
                      :item="item"
                      itemtype="City Subscriber"
                      @setDelete="deleteFn"
                    />
                  </template>
                  <template v-slot:item.status>
                    <v-btn icon color="grey lighten-1">
                      <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import adminsApicalls from "../../../services/admin.sevice";
import CheckEmail from "../../../components/util/CheckEmail";

import DisableConfirmation from "@/dashboard/Common/DisableConfirmation";
import DeleteConfirmation from "@/dashboard/Common/DeleteConfirmation";
export default {
  name: "Admins",
  components: {
    DisableConfirmation,
    DeleteConfirmation,
  },

  data() {
    return {
      search: "",
      isLoading: false,
      deleteDialog: false,
      userName: null,
      emailAddress: null,
      password: null,
      mainError: null,
      // secKey: null,
      adType: null,
      adminTypes: [
        {
          value: "superAdmin",
          name: "Super Admin",
        },
        { value: "admin", name: "Admin" },
      ],
      errorUserName: null,
      errorEmailAddress: null,
      errorPassword: null,
      errorAdType: null,
      staticErrorMessages: {
        userName: {
          isEmpty: "User Name cannot be empty",
        },
        emailAddress: {
          isEmpty: "Email Address cannot be empty",
        },
        password: {
          isEmpty: "Password cannot be empty",
        },
        adType: {
          isEmpty: "Please select a suitable Admin Type",
        },
      },
      // headers: [
      //   {
      //     sortable: true,
      //     text: "Name",
      //     value: "name",
      //     class: "primary--text title",
      //   },
      //   {
      //     sortable: true,
      //     text: "Email",
      //     value: "email",
      //     class: "primary--text title",
      //   },

      //   {
      //     sortable: true,
      //     text: "Admin Type",
      //     value: "adminType",
      //     class: "primary--text title",
      //   },
      //   {
      //     sortable: false,
      //     text: "Disable",
      //     value: "approval",
      //     class: "primary--text title",
      //   },
      //   {
      //     sortable: false,
      //     text: "Delete",
      //     value: "delete",
      //     class: "primary--text title",
      //   },
      // ],
      items: [],
    };
  },
  computed: {
    computedadminType() {
      let admin = [];
      if (this.adType == "Super Administrator") {
        admin.push({
          name: "superAdmin",
        });
      }
      if (this.adType == "admin") {
        admin.push({
          name: "superAdmin",
        });
      }
      return admin;
    },
    headers() {
      let headerArray = [
        {
          sortable: true,
          text: "Name",
          value: "name",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Email",
          value: "email",
          class: "primary--text title",
        },

        {
          sortable: true,
          text: "Admin Type",
          value: "adminType",
          class: "primary--text title",
        },
      ];

      let adminType = JSON.parse(localStorage.getItem("admin")).admin.adminType;

      if (adminType == "master") {
        headerArray.push(
          {
            sortable: false,
            text: "Disable",
            value: "approval",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Delete",
            value: "delete",
            class: "primary--text title",
          }
        );
      }

      return headerArray;
    },
  },
  watch: {
    userName() {
      this.errorUserName = null;
    },
    emailAddress() {
      this.errorEmailAddress = null;
    },
    password() {
      this.errorPassword = null;
    },
    adType() {
      this.errorAdType = null;
    },
  },
  mounted() {
    this.isLoading = true;
    adminsApicalls.getAllAdmin().then((res) => {
      this.items = res.data.content.data.map((x) => {
        return {
          ...x,
          isDeleteModal: false,
          isDisableModal: false,
        };
      });
      this.isLoading = false;
      // console.log(res.data.content.data);
    });
  },
  methods: {
    checkEmailFn(emailAddress) {
      if (emailAddress) {
        return CheckEmail(emailAddress);
      } else {
        return true;
      }
    },
    regFn() {
      this.isLoading = true;
                console.log( this.adType)

      if (this.userName && this.emailAddress && this.password && this.adType) {
        let payload = {
          Name: this.userName,
          Email: this.emailAddress,
          Password: this.password,
          AdminType: this.adType,
        };
        const adminType = this.adType;

        adminsApicalls.createAdmin(payload).then((res) => {
          if (res.data.message == "success") {
            let payload2 = {
              snackBarText: adminType + " Admin created successfully",
              snackBarColor: "secondary",
            };
            this.$store.dispatch("notify/setSnackbarMessage", payload2);
            adminsApicalls.createAdmin().then((res) => {
              this.items = res.data.content.data.map((x) => {
                return {
                  ...x,
                  isDeleteModal: false,
                  isDisableModal: false,
                };
              });
            });
            adminsApicalls.getAllAdmin().then(res => {
            this.items = res.data.content.data;
          });
          this.isLoading=false;
          } else {
            // this.isApiLoad = false;
            this.mainError = res.data.details;
            // this.email = "";
            this.password = "";
            setTimeout(() => {
              this.mainError = null;
            }, 4000);
          }
        });
        this.userName = null;
        this.emailAddress = null;
        this.password = null;
        this.adType = null;
      } else {
        if (!this.userName) {
          this.errorUserName = this.staticErrorMessages.userName.isEmpty;
        }
        if (!this.emailAddress) {
          this.errorEmailAddress =
            this.staticErrorMessages.emailAddress.isEmpty;
        }
        if (!this.password) {
          this.errorPassword = this.staticErrorMessages.password.isEmpty;
        }
        if (!this.adType) {
          this.errorAdType = this.staticErrorMessages.adType.isEmpty;
        }
      }
    },
    approvalFn(val) {
      this.isLoading = true;
      let payload = {
        Id: val._id,
        IsDisable: !val.isDisable,
      };
      // console.log(payload);

      adminsApicalls.setEnable(payload).then((res) => {
        // console.log(res.data);
        if (res.data.message == "success") {
          // let payload2 = {
          //   snackBarText: "Admin has been Enabled Successfully",
          //   snackBarColor: "secondary"
          // };
          this.items = res.data.content.data;
          let payload2 = val.isDisable
            ? {
                snackBarText: `The admin '${val.name}'  has been  disabled successfully`,
                snackBarColor: "secondary",
              }
            : {
                snackBarText: `The admin '${val.name}' has been enabled successfully`,
                snackBarColor: "success",
              };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          // this.$store.dispatch("notify/setSnackbarMessage", payload2);

          adminsApicalls.getAllAdmin().then((res) => {
            this.items = res.data.content.data.map((x) => {
              return {
                ...x,
                isDeleteModal: false,
                isDisableModal: false,
              };
            });

            // console.log("data", res.data.content.data);
          });
          this.isLoading = false;
        }
      });
    },
    deleteFn(val) {
      this.isLoading = true;
      let payload = {
        _id: val._id,
      };
      // console.log(payload);
      adminsApicalls.setDelete(payload).then(() => {
        adminsApicalls.getAllAdmin().then((res) => {
          this.items = res.data.content.data.map((x) => {
            return {
              ...x,
              isDeleteModal: false,
              isDisableModal: false,
            };
          });
          this.isLoading = false;
          // console.log("data", res.data.content.data);
        });
        // console.log(res.data.success);
        // if (res.data.success) {
        //   let payload2 = {
        //     snackBarText: `The city subscriber's '${val.name}' account has been deleted successfully`,
        //     snackBarColor: "secondary"
        //   };
        //   this.deleteDialog = false;
        //   this.$store.dispatch("notify/setSnackbarMessage", payload2);
        //   adminsApicalls.getAllCitySub().then(res => {
        //     this.items = res.data.content.data;
        //   });
        // }
      });
    },
  },
};
</script>
<style>
/* Medium devices (large tablet to laptop, less than 960px) */
@media (max-width: 960px) {
}
</style>
